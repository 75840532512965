import React, { Children, useState } from "react"
import { useForm, FormProvider, appendErrors } from "react-hook-form"
import { ContactGoogleForm } from "./ContactMove"
import { TextInput, Button, DatePicker, Select, Address } from "./ui"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import dateFormat from "dateformat"
import { navigate } from "gatsby"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"
import { motion } from "framer-motion"

const schema = yup.object().shape({
    name1: yup.string().required(`お客様のお名前を入力してください`),
    Eaddress1: yup.string().required(`現在のご住所を選択してください`),
    Eaddress2: yup.string().required(`現在の番地を入力してください`),
    telephone: yup
        .string()
        .matches(
            /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
            "有効な電話番号を入力してください"
        )
        .required(`連絡先のお電話番号を入力してください`),
    email: yup
        .string()
        .email(`正しいメールアドレスを入力してください`)
        .required(`お客様のメールアドレスを入力してください`),
    Edate: yup.string().required(`現在の住所での終了日を指定してください`),
    move: yup.string().required(`どちらかを選択してください`),
    Saddress1: yup.string().when("move", {
        is: "購読する",
        then: yup.string().required(`お引越し先の住所を選択してください`),
    }),
    Saddress2: yup.string().when("move", {
        is: "購読する",
        then: yup.string().required(`お引越し先の番地を入力してください`),
    }),
    Sdate: yup.date().when("move", {
        is: "購読する",
        then: yup
            .date()
            .when(
                "Edate",
                (Edate, schema) =>
                    Edate &&
                    schema.min(
                        Edate,
                        "現在の住所の配達終了日よりも後の日付を選択してください"
                    )
            )
            .required(`お引越し先での配達開始日を指定してください`),
    }),
    pay: yup.string().when("move", {
        is: "購読する",
        then: yup.string().required(`どちらかを選択してください`),
    }),
    other: yup.string().when("pay", {
        is: "その他",
        then: yup.string().required(`その他の支払方法を入力してください`),
    }),
})

const IconRight = ({ spin = false }) => {
    if (spin) {
        return (
            <span
                className="spin"
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    animationDuration: "5s",
                }}
            >
                <Loader />
            </span>
        )
    }
    return <Send />
}

type FormData = {
    name1: string
    name2: string
    Eaddress1: string
    Eaddress2: string
    Eaddress3: string
    telephone: string
    email: string
    Sdate: string
    move: string
    Saddress1: string
    Saddress2: string
    Saddress3: string
    Edate: string
    pay: string
    other: string
    message: string
}

const Moving = () => {
    const { errors, watch, ...methods } = useForm<FormData>({
        resolver: yupResolver(schema),
    })
    const [transactionState, setTransactionState] = useState(false)
    const { promiseInProgress } = usePromiseTracker()
    const move = watch("move")
    const pay = watch("pay")

    const submit = values => {
        const Sdate = dateFormat(values.Sdate, "isoDate")
        const Edate = dateFormat(values.Edate, "isoDate")
        const GOOGLE_FORM_ACTION = ContactGoogleForm.action
        // CORS対策
        const CORS_PROXY = "https://cros-nikkei.herokuapp.com/"

        const submitParams = new FormData()
        submitParams.append(
            ContactGoogleForm.name,
            values.name1 + " " + values.name2
        )
        submitParams.append(
            ContactGoogleForm.Eaddress,
            values.Eaddress1 + values.Eaddress2 + " " + values.Eaddress3
        )
        submitParams.append(ContactGoogleForm.telephone, values.telephone)
        submitParams.append(ContactGoogleForm.email, values.email)
        submitParams.append(ContactGoogleForm.Edate, Edate)
        submitParams.append(ContactGoogleForm.move, values.move)
        {
            move === "購読する" && [
                submitParams.append(
                    ContactGoogleForm.Saddress,
                    values.Saddress1 + values.Saddress2 + " " + values.Saddress3
                ),
                submitParams.append(ContactGoogleForm.Sdate, Sdate),
                submitParams.append(ContactGoogleForm.pay, values.pay),
            ]
        }
        {
            pay === "その他" &&
                submitParams.append(ContactGoogleForm.other, values.other)
        }
        submitParams.append(ContactGoogleForm.message, values.message)

        trackPromise(
            axios
                .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
                .then(() => {
                    navigate(`/thanks`)
                })
                .catch(error => {
                    navigate(`/error`)
                })
        )
    }
    return (
        <FormProvider errors={errors} {...methods} watch={watch}>
            <h4 className="text-center text-2xl text-color-4">
                お引越しのご連絡
            </h4>

            <form
                method="POST"
                onSubmit={methods.handleSubmit(submit)}
                noValidate
            >
                <TextInput
                    label="お名前・会社名(部署名)"
                    name="name1"
                    footer={errors.name1?.message}
                />
                <TextInput
                    label="担当者様のお名前(法人のお客様)"
                    name="name2"
                    footer={errors.name2?.message}
                />
                <Address
                    label="ご住所"
                    name="Eaddress1"
                    footer={errors.Eaddress1?.message}
                    value={[
                        { id: 1, value: "日本橋浜町" },
                        { id: 2, value: "日本橋人形町" },
                        { id: 3, value: "日本橋蛎殻町" },
                        { id: 4, value: "日本橋中洲" },
                        { id: 5, value: "日本橋久松町" },
                        { id: 6, value: "東日本橋" },
                        { id: 7, value: "日本橋富沢町" },
                        { id: 8, value: "日本橋横山町" },
                        { id: 9, value: "日本橋馬喰町" },
                        { id: 10, value: "日本橋大伝馬町" },
                        { id: 11, value: "日本橋小伝馬町" },
                        { id: 12, value: "日本橋本町" },
                        { id: 13, value: "日本橋小舟町" },
                        { id: 14, value: "日本橋箱崎町" },
                        { id: 15, value: "日本橋堀留町" },
                    ]}
                />
                <TextInput
                    label="番地"
                    name="Eaddress2"
                    footer={errors.Eaddress2?.message}
                />
                <TextInput
                    label="建物名・部屋番号"
                    name="Eaddress3"
                    footer={errors.Eaddress3?.message}
                />
                <TextInput
                    label="お電話番号"
                    name="telephone"
                    footer={errors.telephone?.message}
                />
                <TextInput
                    label="メールアドレス"
                    name="email"
                    type="email"
                    footer={errors.email?.message}
                />
                <DatePicker
                    label="現住所の配達終了日を選択してください"
                    name="Edate"
                    type="date"
                    min={new Date()}
                    footer={errors.Edate?.message}
                />
                <Select
                    label="転居先でも購読なさいますか？"
                    name="move"
                    footer={errors.move?.message}
                    value={[
                        { id: 1, value: "購読する" },
                        { id: 2, value: "購読しない" },
                    ]}
                />
                {move === "購読する" && (
                    <>
                        <TextInput
                            label="新しいご住所"
                            name="Saddress1"
                            footer={errors.Saddress1?.message}
                        />
                        <TextInput
                            label="番地"
                            name="Saddress2"
                            footer={errors.Saddress2?.message}
                        />
                        <TextInput
                            label="建物名・部屋番号"
                            name="Saddress3"
                            footer={errors.Saddress3?.message}
                        />
                        <DatePicker
                            label="転居先での配達開始日を選択してください"
                            name="Sdate"
                            type="date"
                            min={
                                methods.getValues("Edate")
                                    ? methods.getValues("Edate")
                                    : new Date()
                            }
                            footer={errors.Sdate?.message}
                        />
                        <Select
                            label="転居先でのお支払い方法を選択してください"
                            name="pay"
                            footer={errors.pay?.message}
                            value={[
                                { id: 1, value: "これまでと同じ" },
                                { id: 2, value: "その他" },
                            ]}
                        />
                        {pay === "その他" && (
                            <TextInput
                                label="その他の支払方法"
                                name="other"
                                footer={errors.other?.message}
                            />
                        )}
                    </>
                )}

                <TextInput label="通信欄" name="message" footer={""} />
                <div className="py-3 lg:p-4">
                    <Button
                        type="button,submit"
                        title="送信"
                        disabled={promiseInProgress}
                        iconRight={<IconRight spin={promiseInProgress} />}
                        aria-label="送信"
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default Moving
