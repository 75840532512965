export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/9/d/e/1FAIpQLSc83Z9Aoa_GhrGQmQgbH3afklRVqoy_BfDYfTElex8mkteACw/formResponse",

    name: "entry.820369502",
    address: "entry.530811576",
    telephone: "entry.1943648892",
    email: "entry.1995128216",
    Sdate: "entry.1571053466",
    start: "entry.1703466874",
    Edate: "entry.287068378",
    end: "entry.1530152282",
    keep: "entry.1775249852",
    message: "entry.5663495",
}
