export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/9/d/e/1FAIpQLSdrkTj9WzFnayctKT-WbLmPmXel7zst36wYlgSou6O-L8NSDw/formResponse",

    name: "entry.556036945",
    Eaddress: "entry.1434797568",
    telephone: "entry.818375819",
    email: "entry.863916015",
    Edate: "entry.55735969",
    move: "entry.1206683618",
    Saddress: "entry.430682693",
    Sdate: "entry.1832686134",
    pay: "entry.2110139505",
    other: "entry.1866698358",
    message: "entry.545700466",
}
