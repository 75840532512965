export const ContactGoogleForm = {
    action:
        "https://docs.google.com/forms/u/9/d/e/1FAIpQLSeNg4SfI1dv1ajIgzL21zeXLW0uUtj18zN-8nGzSEbCldWHrw/formResponse",

    name: "entry.1230052355",
    address: "entry.531936724",
    telephone: "entry.116878444",
    email: "entry.1044703197",
    sub: "entry.964676923",
    plan: "entry.1740891253",
    paper: "entry.606555040",
    check: "entry.606555040",
    Sdate: "entry.808958210",
    message: "entry.2060791017",
}
